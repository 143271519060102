export const socialLinks = [
    {
        id: 1,
        name: "Website",
        icon: "globe",
        url: "https://wazzac.dev",
    },
    {
        id: 2,
        name: "GitHub",
        icon: "github",
        url: "https://github.com/wazzac",
    },
    {
        id: 4,
        name: "LinkedIn",
        icon: "linkedin",
        url: "https://www.linkedin.com/in/warrencoetzee/",
    }
];
